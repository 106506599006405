const initState = {
  pagesList: [],
  pageOne: {},
  allPages: [],
  page: 1,
  limit: 10,
  count: 0,
};
export const Pages = (state = initState, action) => {
  switch (action.type) {
    case 'SET_PAGES': {
      return {
        ...state,
        pagesList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    }
    case 'SET_ALL_PAGES': {
      return {
        ...state,
        allPages: action.payload.data,
      };
    }
    case 'SET_ONE_PAGE': {
      return {
        ...state,
        pageOne: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
