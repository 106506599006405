const initState = {
  storeList: [],
  store: {},
  page: 1,
  limit: 10,
  count: 0,
  currentStore: {},
  contactStore: {},
  contactStoreList: [],
  address: "",
  showModal: true,
};
export const Store = (state = initState, action) => {
  switch (action.type) {
    case "SET_STORES": {
      return {
        ...state,
        storeList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    }
    case "SET_CONTACT_STORES": {
      return {
        ...state,
        contactStoreList: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    }
    case "SET_CURRENT_STORE": {
      return {
        ...state,
        currentStore: action.payload.data,
      };
    }
    case "SET_CONTACT_STORE": {
      return {
        ...state,
        contactStore: action.payload.data,
      };
    }
    case "SET_ADDRESS": {
      return {
        ...state,
        address: action.payload.data,
      };
    }
    case "SET_MODAL": {
      return {
        ...state,
        showModal: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
