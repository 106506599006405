const initState = {
  scrollerBanner: [],
  totalScrollerBanner: 0,
  horizontalBlock: [],
  totalHorizontalBlock: 0,
  verticalBlock: [],
  totalVerticalBlock: 0,
  pageBanner: [],
  totalPageBanner: 0,
  horizontalBanner: [],
  totalHorizontalBanner: 0,
};

const BannerReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_HORIZONTAL_BANNER':
      return {
        ...state,
        horizontalBanner: action.payload.data,
        totalHorizontalBanner: action.payload.total,
      };
    case 'SET_PAGE_BANNER':
      return {
        ...state,
        pageBanner: action.payload.data,
        totalPageBanner: action.payload.total,
      };
    case 'SET_VERTICAL_BLOCK':
      return {
        ...state,
        verticalBlock: action.payload.data,
        totalVerticalBlock: action.payload.total,
      };
    case 'SET_SCROLLER_BANNER':
      return {
        ...state,
        scrollerBanner: action.payload.data,
        totalScrollerBanner: action.payload.total,
      };
    case 'SET_HORIZONTAL_BLOCK':
      return {
        ...state,
        horizontalBlock: action.payload.data,
        totalHorizontalBlock: action.payload.total,
      };
    default:
      return { ...state };
  }
};

export default BannerReducer;
