export const setCart = (value, page, limit, count) => {
    return {
      type: 'SET_CART',
      payload: { data: value}
    };
  };
  export const setPONumber=(value)=>{
    return {
      type: 'SET_PO_NUMBER',
      payload: { data: value}
    };
  }