import history from '../../../app/history';
import { api } from '../../../api/api';
import { setNews } from '../news/newsAction';
import { setPromotion, setActivePromotion } from '../promotion/promotionAction';
import { setSubscriber } from '../subscriber/subscriberAction';
import { toastr } from 'react-redux-toastr';
import { setLasherCatalogue } from '../lasherCatalogue/lasherCatalogueAction';
import { setMarketingCategory } from '../marketingCategory/marketingCategoryAction';
import { setTrainingCategory } from '../trainingCategory/trainingCategoryAction';
import { setMarketingItem } from '../marketingItem/marketingItemAction';
import { setTrainingItem } from '../trainingItem/trainingItemAction';
import { setAuditTrail } from '../auditTrail/auditTrailAction';
import { setStockSheet } from '../stockSheet/stockSheetAction';
import { setRelation } from '../relation/relationAction';
import { setCompany } from '../company/companyAction';
import { setCompanyUsers, setUser } from '../companyUsers/companyUsersAction';
import { setRepContactAccount } from '../repContactAccounts/repContactAccount';
import { setRepContact } from '../repContact/repContact';
import { setSwitchAccount } from '../switchAccount/switchAccountAction';
import {
  setMyCatalogue,
  setAllCatalogueProducts,
  setTopProducts,
} from '../myCatalogue/myCatalogueAction';
import { setOpenOrder } from '../openOrder/openOrderAction';
import { setBackOrder } from '../backOrder/backOrderAction';
import { setInvoice } from '../invoice/invoiceAction';
import { setCart } from '../cart/cartAction';
import { setAdminDashboard } from '../adminDashboard/adminDashboardAction';
import {
  setCartCount,
  setCategoryList,
} from '../searchMyCatalogue/searchMyCatalogueAction';

import { setTopMenu, setOneTopMenu } from '../b2cCms/topMenuAction';
import { setProductMenu } from '../b2cCms/productMenuAction';
import { setCareers } from '../b2cCms/careersAction';
import { setStores, setContactStores } from '../b2cCms/storeAction';
import { setPages, setAllPages, setOnePage } from '../b2cCms/pageAction';
import { setLoginBackground } from '../b2cCms/backgroundAction';

import { setB2bBlocksData } from '../b2bCms/homeBlockAction';
import {
  setHorizontalBanner,
  setPageBanner,
  setVerticalBlock,
  setScrollerBanner,
  setHorizontalBlock,
} from '../banner/bannerAction';
export const addInvoice = (id, invoice) => {
  return async (dispatch) => {
    let response = await api(`/addPrevInvoice/${id}/${invoice}`, {}, 'patch');
    if (response.status === 200) {
      toastr.success('Added to cart successfully');
    } else {
      toastr.error('Something Went Wrong. Try Again');
    }
  };
};

export const b2bBlockMenuApi = () => {
  return async (dispatch) => {
    let response = await api(`/menu/admin?type=B2B_HOME`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setB2bBlocksData(response.data.data));
    } else {
      setB2bBlocksData(setTopMenu([]));
    }
  };
};

export const allCatalogueProductsApi = (id, search = '', searchType = '') => {
  return async (dispatch) => {
    const data = {
      search,
    };
    let response = await api(
      `/allProductsWithPricing/${id}?search=${search}&searchType=${searchType}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setAllCatalogueProducts(response.data.data));
    } else {
      dispatch(setAllCatalogueProducts([]));
    }
  };
};

export const getLoginBackground = () => {
  return async (dispatch) => {
    let response = await api(`/cms/background`, {}, 'get');
    if (response && response.status === 200) {
      if (response.data.data && response.data.data.fileUrl) {
        dispatch(setLoginBackground(response.data.data.fileUrl));
      } else {
        dispatch(setLoginBackground(''));
      }
    } else {
      toastr.error('Login background not added');
    }
  };
};
export const postQuote = async (id, PONumber, note, cart) => {
  let currentStore = 'AGRITRACK';
  let response = await api(
    `/quoteProcessB2C/${id}`,
    { PONumber: PONumber, note, fromB2C: true, cart, store: currentStore.code },
    'post'
  );
  // localStorage.setItem(
  //   'AgritrackCart',
  //   JSON.stringify({ productsWithQty: [] })
  // );
  return response;
};

export const postReQuoteB2C = async (id, PONumber, note, cart, salesOrder) => {
  let currentStore = 'AGRITRACK';
  let response = await api(
    `/requoteProcessB2C/${id}`,
    {
      PONumber: PONumber,
      note,
      fromB2C: true,
      cart,
      store: currentStore,
      salesOrder,
    },
    'post'
  );
  // localStorage.setItem(
  //   'AgritrackCart',
  //   JSON.stringify({ productsWithQty: [] })
  // );
  return response;
};

export const postOrderApi = async (
  id,
  PONumber,
  shippingOption,
  partialShipping,
  note,
  cart,
  quoteNo = ''
) => {
  let response = await api(
    `/orderProcessB2C/${id}`,
    {
      PONumber: PONumber,
      shippingOption: shippingOption,
      partialShipping: partialShipping,
      note,
      cart,
      fromB2C: true,
      quoteNo,
    },
    'post'
  );
  localStorage.setItem(
    'AgritrackCart',
    JSON.stringify({ productsWithQty: [] })
  );
  return response;
};
export const checkCheckoutStatusApi = (id) => {
  return async (dispatch, store) => {
    let cart = JSON.parse(localStorage.getItem('AgritrackCart'));
    let currentStore = JSON.parse(localStorage.getItem('storeData'));
    let res = await api(
      `/pay/statusB2C`,
      { checkoutId: id, cart, store: currentStore.code },
      'post'
    ).catch((err) => {});
    if (res) {
      if (res.status !== 200) {
        // toastr.error("Gyms",'Some error Occured. Try again');
        // console.log("error");
        toastr.error(`${res.data.error || 'Order Failed'}`);
        return false;
        // history.push("/userinfo");
      } else {
        toastr.success(
          `Payment Successful
            ${
              res.data.data.successMessage.length
                ? res.data.data.successMessage.length === 1
                  ? res.data.data.successMessage
                  : '-' + res.data.data.successMessage
                : ''
            }`
        );
        return true;
        // history.push("/userinfo");
      }
    }
  };
};
export const saveOrderApi = (id, account) => {
  return async (dispatch, store) => {
    let res = await api(`/saveOrder`, { userId: id, account }, 'post').catch(
      (err) => {}
    );
    if (res) {
      if (res.status !== 200) {
        // toastr.error("Gyms",'Some error Occured. Try again');
        // console.log("error");
        toastr.error(`${res.data.error || 'Order Not Saved'}`);
        return false;
        // history.push("/userinfo");
      } else {
        toastr.success('Order Saved Successful');
        localStorage.setItem(
          'AgritrackCart',
          JSON.stringify({ productsWithQty: [] })
        );
        history.push({
          pathname: '/Orders/complete',
          state: { ...res.data, type: 'ORDER' },
        });
        return true;
        // history.push("/userinfo");
      }
    }
  };
};

export const addLoginBackground = (data) => {
  return async (dispatch) => {
    let response = await api(`/cms/background`, data, 'post');
    if (response && response.status === 200) {
      toastr.success('Login background added successfully');
    } else {
      toastr.error('Login background not added');
    }
  };
};

export const addShippingNote = (data) => {
  return async (dispatch) => {
    let response = await api(`/order/shippingNote`, data, 'post');
    if (response && response.status === 200) {
      toastr.success('Shipping Note added successfully');
    } else {
      toastr.error('Shipping Note not added');
    }
  };
};

export const updatePageApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/page/admin/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Page Updated Successfully');
    } else {
      toastr.error('Page Not Updated');
    }
  };
};

export const deletePageApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/page/admin/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Page Deleted Successfully');
    } else {
      toastr.error('Page Not Deleted');
    }
  };
};

export const addPageApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/page`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Page Created Successfully');
      history.goBack();
    } else {
      toastr.error('Page Not Created');
    }
  };
};

export const allPagesApi = () => {
  return async (dispatch) => {
    let response = await api(`/pages`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAllPages(response.data.data));
    } else {
      dispatch(setAllPages([]));
    }
  };
};

export const pagesListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/page/admin?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setPages(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setPages([]));
    }
  };
};

export const addStoreApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/store`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Store Created Successfully');
      history.goBack();
    } else {
      toastr.error('Store Not Created');
    }
  };
};

export const storeListApi = (search = '', page = 1, limit = 100) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/store?limit=${data.limit}&page=${page}&search=${search}&isActive=true`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setStores(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setStores([]));
    }
  };
};

export const updateStoreApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/store/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Store Updated Successfully');
    } else {
      toastr.error('Store Not Updated');
    }
  };
};

export const deleteStoreApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/store/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Store Deleted Successfully');
    } else {
      toastr.error('Store Not Deleted');
    }
  };
};

export const careersListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/careers?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCareers(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setCareers([]));
    }
  };
};

export const updateCareerApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/careers/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Career Updated Successfully');
      history.goBack();
    } else {
      toastr.error('Career Not Updated');
    }
  };
};

export const deleteCareerApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/careers/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Career Deleted Successfully');
    } else {
      toastr.error('Career Not Deleted');
    }
  };
};

export const addCareerApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/careers`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Career Created Successfully');
      history.goBack();
    } else {
      toastr.error('Career Not Created');
    }
  };
};

export const addMenuApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/menu`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Menu Created Successfully');
      history.goBack();
    } else {
      toastr.error('Menu Not Created');
    }
  };
};

export const deleteMenuApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Menu Deleted Successfully');
    } else {
      toastr.error('Menu Not Deleted');
    }
  };
};

export const updateMenuApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Menu Updated Successfully');
    } else {
      toastr.error('Menu Not Updated');
    }
  };
};
export const getOneMenuApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/${id}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setOneTopMenu(response.data.data));
    } else {
      dispatch(setOneTopMenu({}));
    }
  };
};
export const getMenuBySlugApi = (slug) => {
  return async (dispatch) => {
    let response = await api(`/menu/slug/${slug}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setOneTopMenu(response.data.data));
    } else {
      dispatch(setOneTopMenu({}));
    }
  };
};

export const updateMenuOrderApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/order`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Menu Order Updated Successfully');
    } else {
      toastr.error('Menu Order Not Updated');
    }
  };
};

export const topMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/menu/admin?type=TOP&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setTopMenu(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setTopMenu([]));
    }
  };
};

export const productMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/menu/admin?type=PRODUCT&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setProductMenu(
          response.data.data,
          page,
          limit,
          response.data.totalCount
        )
      );
    } else {
      dispatch(setProductMenu([]));
    }
  };
};

export const newsApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/news?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setNews(response.data.data, page, limit, response.data.totalNews)
      );
    } else {
      dispatch(setNews([]));
    }
  };
};

export const promotionApi = (storeId, limit = 10) => {
  return async (dispatch) => {
    const data = {
      limit,
    };
    // let response = await api(
    //   `/promotion/${storeId}?limit=${data.limit}`,
    //   {},
    //   'get'
    // );
    // if (response && response.status === 200) {
    //   // let countResponse = await api('/admin/count',{},'get');
    //   // if(countResponse.status === 200){
    //   dispatch(
    //     setPromotion(response.data.data, limit)
    //   );
    //   // }
    // } else {
    dispatch(setPromotion([]));
    // }
  };
};

export const marketingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const trainingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const trainingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const marketingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const marketingCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=marketing`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setMarketingCategory(response.data.data));
      // }
    } else {
      dispatch(setMarketingCategory([]));
    }
  };
};

export const trainingCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=training`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setTrainingCategory(response.data.data));
      // }
    } else {
      dispatch(setTrainingCategory([]));
    }
  };
};

export const activePromotionApi = () => {
  return async (dispatch) => {
    let response = await api(`/activePromotion`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setActivePromotion(response.data.data[0]));
      // }
    } else {
      dispatch(setActivePromotion({}));
    }
  };
};

export const subscriberApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/subscriber?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setSubscriber(
          response.data.data,
          page,
          limit,
          response.data.totalSubscribers
        )
      );
      // }
    } else {
      dispatch(setSubscriber([]));
    }
  };
};

export const lasherCatalogueApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt,-1',
  filter = { category: [], industry: [], brand: [] }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/products?limit=${data.limit}&page=${page}&search=${search}&field=${
        sort.split(',')[0]
      }&direction=${sort.split(',')[1]}`,
      { filter },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setLasherCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setLasherCatalogue([]));
    }
  };
};

export const myCatalogueApi = (
  id,
  customer,
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt,-1',
  filter = { category: [], industry: [], brand: [], promo: [] },
  searchType = '',
  startPrice = '',
  endPrice = '',
  previousLimit = ''
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/productsWithPricing/${id}/${customer}?limit=${
        data.limit
      }&page=${page}&search=${search}&isB2C=true&field=${
        sort.split(',')[0]
      }&direction=${sort.split(',')[1]}`,
      { filter, searchType, startPrice, endPrice },
      'post'
    );
    if (response && response.status === 200) {
      // console.log(response, 'response');
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMyCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalCount,
          filter,
          sort,
          searchType,
          startPrice,
          endPrice
        )
      );
      // }
    } else {
      dispatch(setMyCatalogue([]));
    }
  };
};

export const myCatalogueApiResponse = (
  id,
  customer,
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt,-1',
  filter = { category: [], industry: [], brand: [], promo: [] },
  searchType = '',
  startPrice = '',
  endPrice = '',
  previousLimit = ''
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/productsWithPricing/${id}/${customer}?limit=${
        data.limit
      }&page=${page}&search=${search}&isB2C=true&field=${
        sort.split(',')[0]
      }&direction=${sort.split(',')[1]}`,
      { filter, searchType, startPrice, endPrice },
      'post'
    );
    if (response && response.status === 200) {
      // console.log(response, 'response');
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      return response.data.data;
      // }
    } else {
      return [];
    }
  };
};
export const relatedProductsApi = async (store, account, id) => {
  let response = await api(
    `/relatedProducts/${store}/${id}?account=${account}`,
    {},
    'get'
  );
  if (response) {
    return response;
  }
};
export const auditTrailApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/audit?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setAuditTrail(
          response.data.data,
          page,
          limit,
          response.data.totalAudits
        )
      );
      // }
    } else {
      dispatch(setAuditTrail([]));
    }
  };
};

export const StockSheetApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/stocksheetsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setStockSheet(
          response.data.data,
          page,
          limit,
          response.data.totalStockSheets
        )
      );
      // }
    } else {
      dispatch(setStockSheet([]));
    }
  };
};

export const RelationApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/relatedProducts?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setRelation(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setRelation([]));
    }
  };
};

export const CompanyUsersApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/user?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCompanyUsers(
          response.data.data,
          page,
          limit,
          response.data.totalUsers
        )
      );
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CustomerCompanyUsersApi = (
  id,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/userByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setCompanyUsers(
            response.data.data,
            page,
            limit,
            response.data.totalUsers
          )
        );
      else dispatch(setCompanyUsers([]));
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CompanyApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/accounts`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setCompany(response.data.data));
      // }
    } else {
      dispatch(setCompany([]));
    }
  };
};

export const RepContactAccount = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountsForRep/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContactAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setRepContactAccount([]));
      // }
    } else {
      dispatch(setRepContactAccount([]));
    }
  };
};

export const RepContact = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContactsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const SwitchAccountApi = (
  userId,
  accountId,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountByUser/${userId}/${accountId}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const AdminSwitchAccountApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accounts?limit=${data.limit}&page=${page}&searchKey=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const BackOrderApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueOrdersB2C/AGRITRACK?backOrder=true&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setBackOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setBackOrder([]));
    }
  };
};

export const OpenOrderApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let currentStore = JSON.parse(localStorage.getItem('storeData'));
    let response = await api(
      `/uniqueOrdersB2C/AGRITRACK?backOrder=false&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setOpenOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setOpenOrder([]));
    }
  };
};
export const OrdersApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/orders?backOrder=false&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setOpenOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setOpenOrder([]));
    }
  };
};

export const InvoiceApi = (
  id,
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    console.log('api called....');
    let currentStore = JSON.parse(localStorage.getItem('storeData'));
    let response = await api(
      `/uniqueInvoiceB2C/AGRITRACK?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      dispatch(
        setInvoice(response.data.data, page, limit, response.data.totalInvoices)
      );
    } else {
      dispatch(setInvoice([]));
    }
  };
};

export const CartApi = () => {
  return async (dispatch) => {
    let response = JSON.parse(localStorage.getItem('AgritrackCart'));
    // console.log('response', response);
    if (response) {
      dispatch(setCart(response));
      dispatch(
        setCartCount(
          response.productsWithQty && response.productsWithQty.length
            ? response.productsWithQty.length
            : 0
        )
      );
    } else {
      dispatch(setCart({ productsWithQty: [] }));
      dispatch(setCartCount(0));
    }
  };
};

export const AdminDashboardApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboard(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboard({}));
    }
  };
};

export const CategoryListApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/productsCategories`, {}, 'get');
    if (response && response.status === 200) {
      let array = [];
      response.data.data.map((one) => {
        array.push({ id: one.id, title: one.title });
      });

      dispatch(setCategoryList(array));
    } else {
      dispatch(setCategoryList([]));
    }
  };
};

// export const transactionApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/payment/receipts?limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/payment/receipts?limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//       // let countResponse = await api('/admin/count',{},'get');
//       // if(countResponse.status === 200){
//         // console.log(response.data.count);
//         dispatch(
//           setTransactions(
//               response.data.data,
//             page,
//             limit,
//             response.data.count
//           )
//         );
//       // }
//     } else {
//       dispatch(setTransactions([]));
//     }
//   };
// };

// export const serviceProviderApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setServiceProviders(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setServiceProviders([]));
//     }
//   };
// };

// export const pendingEnquiryApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setPendingEnquiries(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setPendingEnquiries([]));
//     }
//   };
// };

// export const categoryApi = () => {
//   return async dispatch => {

//     let  response = await api(`/order/category`,{}, 'get');
//     if (response && response.status === 200) {
//         dispatch(
//           setCategories(
//               response.data.data
//           )
//         );
//     } else {
//       dispatch(setCategories([]));
//     }
//   };
// };

export const updateNews = async (id, data) =>
  await api(`/news/${id}`, data, 'patch');

export const addNews = async (data) => {
  try {
    let response = await api(`/news`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteNews = async (id) => await api(`/news/${id}`, {}, 'delete');

export const updatePromotion = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const updatePromotionStatus = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const addPromotion = async (data) => {
  try {
    let response = await api(`/promotion`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deletePromotion = async (id) =>
  await api(`/promotion/${id}`, {}, 'delete');

export const updateSubscriber = async (id, data) =>
  await api(`/subscriber/${id}`, data, 'patch');

export const addSubscriber = async (data) => {
  try {
    let response = await api(`/subscriber`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteSubscriber = async (id) =>
  await api(`/subscriber/${id}`, {}, 'delete');

export const createCategory = async (data) =>
  await api(`/category`, data, 'post');

export const updateCategory = async (id, data) =>
  await api(`/category/${id}`, data, 'patch');

export const deleteCategory = async (id) =>
  await api(`/category/${id}`, {}, 'delete');

export const deleteOldCategory = async (categoryId, subCategoriesId) =>
  await api(`/categoryUnchecked`, { categoryId, subCategoriesId }, 'delete');

export const deleteItem = async (id) => await api(`/items/${id}`, {}, 'delete');
export const createItem = async (data) => await api(`/items`, data, 'post');
export const updateItem = async (id, data) =>
  await api(`/items/${id}`, data, 'patch');

export const updateProductImage = async (id, image) =>
  await api(`/products/${id}`, { image: image }, 'patch');

export const customerRegistration = async (data) =>
  await api(`/customerAccount`, data, 'post');

export const mailRegisteredCustomer = async (data) =>
  await api(`/sendCustomerRegistrationForm`, { id: data }, 'post');

export const SubmitStandForm = async (data) =>
  await api(`/sendEventStandUpForm`, data, 'post');

export const SubmitStandAgreementForm = async (data) =>
  await api(`/sendStandAgreementForm`, data, 'post');

export const createStockSheet = async (data) =>
  await api(`/stocksheets`, data, 'post');

export const deleteStockSheet = async (id) =>
  await api(`/stocksheets/${id}`, {}, 'delete');

export const updateStockSheet = async (data, id) =>
  await api(`/stocksheets/${id}`, data, 'patch');

export const getAllProducts = async () => await api(`/allproducts`, {}, 'get');

export const createRelation = async (data) =>
  await api(`/relatedProducts`, data, 'post');

export const updateRelation = async (data, id) =>
  await api(`/relatedProducts/${id}`, data, 'patch');

export const deleteRelation = async (id) =>
  await api(`/relatedProducts/${id}`, {}, 'delete');

export const createUser = (data) => {
  return async (dispatch) => {
    let response = await api(`/user`, { ...data, sendMail: true }, 'post');
    return response;
  };
};

export const updateUser = async (id, data) =>
  await api(`/user/${id}`, data, 'patch');

export const deleteUser = async (id, account) =>
  await api(`/user/${id}`, { account: account }, 'delete');

export const createRepContact = async (data) =>
  await api(`/createRepContacts`, data, 'post');

export const updateCart = async (id, data) => {
  let response = await api(`/carts/${id}`, data, 'patch');
  // console.log(response, 'updatedCart');
  return response;
};

export const postOrder = async (data) => {
  // console.log(data);
  let response = await api(`/orderProcess`, { ...data, fromB2C: true }, 'post');
  return response;
};
export const sendProductData = async (id, data) => {
  let response = await api(
    `/sendProductDetail`,
    { account: id, details: data },
    'post'
  );
  return response;
};

export const resendPassword = async (email) => {
  let response = await api(`/resendPassword-b2c`, { email: email }, 'post');
  return response;
};
export const getProductBySlug = async (store, account, slug) => {
  let response = await api(
    `/productBySlug/${store}/${slug}?account=${account}`,
    {},
    'get'
  );
  if (response) {
    return response;
  }
};
export const getProductByProductId = async (id) => {
  let response = await api(`/productByProductId/${id}`, {}, 'get');
  if (response) {
    return response;
  }
};
export const getProductIdCart = async (account, code) => {
  let response = await api(
    `/productsWithPricing/${account}/product/${code}`,
    {},
    'get'
  );
  return response;
};

export const stocksheetToCart = async (account, id) => {
  let response = await api(`/stocksheets/${id}/${account}`, {}, 'get');
  // console.log(response, 'stocksheetToCart');
  return response;
};

export const allRepContact = (
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  let date = new Date(start).getTime();
  // console.log(date);
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContacts?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response);
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const updateAccountApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/accountFromSyspro/${id}`, {}, 'patch');
    // if(respon)
  };
};

export const printStocksheet = (id, accountId) => {
  return async (dispatch) => {
    let response = await api(`/printStocksheet/${id}/${accountId}`, {}, 'get');
    return response;
  };
};
export const printCart = (id) => {
  return async (dispatch) => {
    let response = await api(`/printCart/${id}`, {}, 'get');
    return response;
  };
};
export const printProducts = (id, products) => {
  return async (dispatch) => {
    let response = await api(
      `/productsPrint/${id}`,
      { products: products },
      'post'
    );
    // console.log(response);
    return response;
  };
};

export const getBannerApi = (type, page = 1, limit = 10) => {
  return async (dispatch) => {
    let response = await api(
      `/banner?type=${type}&page=${page}&limit=${limit}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      let data = response.data.data;
      switch (type) {
        case 'Horizontal_Block':
          dispatch(setHorizontalBlock(data.banners, data.total));
          break;
        case 'Vertical_Block':
          dispatch(setVerticalBlock(data.banners, data.total));
          break;
        case 'Scroll_Banner':
          dispatch(setScrollerBanner(data.banners, data.total));
          break;
        case 'Page_Banner':
          dispatch(setPageBanner(data.banners, data.total));
          break;
        case 'Horizontal_Banner':
          dispatch(setHorizontalBanner(data.banners, data.total));
          break;
        default:
          dispatch(setHorizontalBlock(data.banners, data.total));
      }
      data.page = page;
      data.limit = limit;
      return data;
    } else {
      toastr.error('Something Went Wrong. Try Again');
      return {};
    }
  };
};
export const onePageConfigApi = (page) => {
  return async (dispatch) => {
    let response = await api(`/pageConfig/${page}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setOnePage(response.data.data));
    } else {
      dispatch(setOnePage({}));
    }
  };
};
export const newsMoreApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/more-news/${id}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setNews(response.data.data, 1, 3, response.data.totalNews));
    } else {
      dispatch(setNews([]));
    }
  };
};
export const careersMoreApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/more-careers/${id}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setCareers(response.data.data, 1, 3, response.data.totalCareers)
      );
    } else {
      dispatch(setCareers([]));
    }
  };
};
export const postReQuote = async (id, PONumber, products, salesOrder) => {
  let response = await api(
    `/quoteReProcess/${id}`,
    { PONumber: PONumber, products, fromB2C: true, salesOrder },
    'post'
  );
  return response;
};
export const postQuoteOrderApi = async (
  id,
  PONumber,
  shippingOption,
  partialShipping,
  salesOrder,
  cart,
  freight
) => {
  let response = await api(
    `/quoteOrderProcessB2C/${id}`,
    {
      PONumber: PONumber,
      shippingOption: shippingOption,
      partialShipping: partialShipping,
      salesOrder: salesOrder,
      cart: cart,
      fromB2C: true,
      freight,
    },
    'post'
  );
  localStorage.setItem(
    'AgritrackCart',
    JSON.stringify({ productsWithQty: [] })
  );
  return response;
};

export const printOrders = (
  id,
  { products, orderDate, orderNo, type, orderPo, isImage }
) => {
  return async (dispatch) => {
    let response = await api(
      `/printOrders/${id}`,
      {
        products: products,
        orderDate: orderDate,
        orderNo: orderNo,
        type: type,
        orderPo: orderPo,
        isImage: isImage,
      },
      'post'
    );
    // console.log(response);
    return response;
  };
};
export const checkCheckoutQuoteOrderStatusApi = (id) => {
  return async (dispatch, store) => {
    let currentStore = JSON.parse(localStorage.getItem('storeData'));
    let res = await api(
      `/quoteOrderPay/statusB2C`,
      { checkoutId: id, store: currentStore.code },
      'post'
    ).catch((err) => {});
    if (res) {
      if (res.status !== 200) {
        // toastr.error("Gyms",'Some error Occured. Try again');
        // console.log("error");
        toastr.error(`${res.data.error || 'Order Failed'}`);
        return false;
        // history.push("/userinfo");
      } else {
        toastr.success(
          `Payment Successful
            ${
              res.data.data.successMessage.length
                ? res.data.data.successMessage.length === 1
                  ? res.data.data.successMessage
                  : '-' + res.data.data.successMessage
                : ''
            }`
        );
        return true;
        // history.push("/userinfo");
      }
    }
  };
};
export const changePassword = async (id, newpassword) =>
  await api(`/changePassword/${id}`, { newpassword }, 'patch');

export const updateUserApi = async (id, data) => {
  let response = await api(`/user-account/${id}`, data, 'patch');
  if (response && response.status === 200) {
    toastr.success('Details Updated Successfully');
  } else {
    toastr.error(response.data.error || 'Details  Not Updated');
  }
};

export const UserApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/user/${id}`, {}, 'get');
    if (response && response.status === 200) {
      if (response.data.data) {
        dispatch(setUser(response.data.data));
      } else {
        dispatch(setUser({}));
      }
    } else {
      dispatch(setUser({}));
    }
  };
};
export const sendContactQueryApi = async (data) => {
  let response = await api(`/send-query`, data, 'post');
  if (response && response.status === 200) {
    toastr.success('Message sent successfully');
    history.push('/');
  } else {
    toastr.error('Message not sent. Please try after sometime');
  }
};

export const TopSellingProductApi = (store = 'AGRITRACK') => {
  return async (dispatch) => {
    let response = await api(`/top-selling-products?store=${store}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setTopProducts(response.data.data));
    } else {
      dispatch(setTopProducts([]));
    }
  };
};
export const ProductCommentsApi = async (productId, page = 1, limit = 10) => {
  let response = await api(
    `/comments?product=${productId}&page=${page}&limit=${limit}`,
    {},
    'get'
  );
  if (response.status === 200) {
    return { data: response.data.data, total: response.data.total };
  } else {
    return { data: [], total: 0 };
  }
};

export const CreateCommentsApi = async (data) => {
  let response = await api(`/comment`, data, 'post');
  if (response.status === 201) {
    return true;
  } else {
    return false;
  }
};
export const CreateRatingApi = async (data) => {
  let response = await api(`/rating`, data, 'post');
  if (response.status === 201) {
    return true;
  } else {
    return false;
  }
};
export const ProductRatingsApi = async (productId) => {
  let response = await api(`/ratings/${productId}`, {}, 'get');
  if (response.status === 200) {
    return {
      data: response.data.data,
      total: response.data.total,
      totalRating: response.data.totalRatings,
    };
  } else {
    return { data: [], total: 0, totalRating: 0 };
  }
};

export const getUserProductRating = async (productId) => {
  let response = await api(`/user-rating/${productId}`, {}, 'get');
  if (response.status === 200) {
    return response.data.data;
  } else {
    return {};
  }
};

export const getFaqsByProduct = async (productId) => {
  let response = await api(
    `/faqs?product=${productId}&page=1&limit=99999`,
    {},
    'get'
  );
  if (response.status === 200) {
    return response.data.data;
  } else {
    return [];
  }
};
export const headOfficeStock = async (code) => {
  let response = await api(`/headOfficeStock`, { code }, 'post');
  return response;
};
export const pricingCron = async (company, code) => {
  let response = await api(
    `/cron-pricing?company=${company}&customer=CODWEB`,
    { code },
    'post'
  );
  return response;
};
export const contactStoreListApi = (search = '', page = 1, limit = 100) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/store?limit=${data.limit}&page=${page}&search=${search}&isActive=true&catalogue=all`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setContactStores(
          response.data.data,
          page,
          limit,
          response.data.totalCount
        )
      );
    } else {
      dispatch(setContactStores([]));
    }
  };
};

export const createService = async (data) => {
  let response = await api(`/service`, data, 'post');
  if (response && response.status === 201) {
    toastr.success('Your service request has been submitted.');
    history.push('/');
  } else {
    toastr.error('Service creation failed');
  }
};
export const createTransaction = async (data) =>
  await api(`/transactions`, data, 'post');
export const updateTransaction = async (id, data) =>
  await api(`/transactions/${id}`, data, 'post');
export const getOneTransaction = async (id) =>
  await api(`/transaction/${id}`, {}, 'get');
export const checkPoNumberApi = async (accountId, poNumber) =>
  await api(`/check-po/${accountId}/${poNumber}`, {}, 'get');

export const updateWishlist = async (data) => {
  let response = await api(`/wishlist`, data, 'post');
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};
