export const setPages = (value, page, limit, count) => {
  return {
    type: 'SET_PAGES',
    payload: { data: value, page, limit, count },
  };
};

export const setAllPages = (value) => {
  return {
    type: 'SET_ALL_PAGES',
    payload: { data: value },
  };
};
export const setOnePage = (value) => {
  return {
    type: 'SET_ONE_PAGE',
    payload: { data: value },
  };
};
