export const setCompanyUsers = (value, page, limit, count) => {
    return {
      type: 'SET_COMPANY_USERS',
      payload: { data: value, page, limit, count }
    };
    
  };
  export const setUser = (value) => {
    return {
      type: 'SET_USER',
      payload: { data: value}
    };
    
  };