const initState = {
  topMenuList: [],
  topMenu: {},
  productMenuList: [],
  productMenu: {},
  topPage: 1,
  topLimit: 10,
  topCount: 0,
  productPage: 1,
  productLimit: 10,
  productCount: 0,
};
export const Menu = (state = initState, action) => {
  switch (action.type) {
    case 'SET_TOP_MENU': {
      return {
        ...state,
        topMenuList: action.payload.data,
        topPage: action.payload.page,
        topLimit: action.payload.limit,
        topCount: action.payload.count,
      };
    }
    case 'SET_PRODUCT_MENU': {
      return {
        ...state,
        productMenuList: action.payload.data,
        productPage: action.payload.page,
        productLimit: action.payload.limit,
        productCount: action.payload.count,
      };
    }
    case 'SET_ONE_TOP_MENU': {
      return {
        ...state,
        oneTopMenu: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
