
  
const initState = {
    promotion: [],
    limit: 10,
    activePromotion:{}
  };
  
  const PromotionReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_PROMOTION':
        return {
          ...state,
          promotion: action.payload.data,
          limit: action.payload.limit,
        };
        case 'SET_ACTIVE_PROMOTION':
          return {
            ...state,
            activePromotion: action.payload.data
          };
      case 'RESET_STATE_PROMOTION':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default PromotionReducer;
  