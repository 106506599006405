export const setStores = (value, page, limit, count) => {
  return {
    type: "SET_STORES",
    payload: { data: value, page, limit, count },
  };
};
export const setContactStore = (value) => {
  return {
    type: "SET_CONTACT_STORE",
    payload: { data: value },
  };
};
export const setContactStores = (value, page, limit, count) => {
  return {
    type: "SET_CONTACT_STORES",
    payload: { data: value, page, limit, count },
  };
};
export const setCurrentStore = (value) => {
  return {
    type: "SET_CURRENT_STORE",
    payload: { data: value },
  };
};
export const setAddress = (value) => {
  return {
    type: "SET_ADDRESS",
    payload: { data: value },
  };
};
export const setModal = (value) => {
  return {
    type: "SET_MODAL",
    payload: { data: value },
  };
};
