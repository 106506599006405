import { store } from '../redux/storeConfig/store';

import history from '../app/history';

const axios = require('axios');

const mainUrl = 'https://orders.agri-track.co.za/api';
// const mainUrl = 'https://agritrack.remoteon.co.za/api';
// const mainUrl = "http://41.189.76.187/api";
// const mainUrl = 'http://localhost:1337';

// const mainUrl = 'https://orders.lasher.co.za:1337';
/**
 *
 * @param {apiEndpoint} endpoint
 * @param {value} data
 * @param {get,post,patch,delete,put} type
 */
export const api = async (endpoint, data, type) => {
  var res;

  var token = store.getState().auth.login.values.loggedInUser
    ? store.getState().auth.login.values.loggedInUser.token
    : '';
  switch (type) {
    case 'post':
      await axios({
        data: data,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          console.log(err);

          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503 ||
            err.response.status === 500
          ) {
            localStorage.removeItem('userData');
            store.dispatch({
              type: 'SET_LOGIN_FLAG',
              payload: { data: false },
            });
            store.dispatch({
              type: 'CHANGE_ROLE',
              payload: { data: '' },
            });
            store.dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
            history.push('/');
          } else {
            res = err.response;
          }
        });
      break;
    case 'get':
      await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503 ||
            err.response.status === 500
          ) {
            localStorage.removeItem('userData');
            store.dispatch({
              type: 'SET_LOGIN_FLAG',
              payload: { data: false },
            });
            store.dispatch({
              type: 'CHANGE_ROLE',
              payload: { data: '' },
            });
            store.dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
            history.push('/');
          } else {
            res = err.response;
          }
        });
      break;
    case 'put':
      await axios({
        method: 'put',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('userData');
            store.dispatch({
              type: 'SET_LOGIN_FLAG',
              payload: { data: false },
            });
            store.dispatch({
              type: 'CHANGE_ROLE',
              payload: { data: '' },
            });
            store.dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
            history.push('/');
          } else {
            res = err.response;
          }
        });
      break;
    case 'patch':
      await axios({
        method: 'patch',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('userData');
            store.dispatch({
              type: 'SET_LOGIN_FLAG',
              payload: { data: false },
            });
            store.dispatch({
              type: 'CHANGE_ROLE',
              payload: { data: '' },
            });
            store.dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
            history.push('/');
          } else {
            res = err.response;
          }
        });
      break;
    case 'delete':
      await axios({
        data: data,
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('userData');
            store.dispatch({
              type: 'SET_LOGIN_FLAG',
              payload: { data: false },
            });
            store.dispatch({
              type: 'CHANGE_ROLE',
              payload: { data: '' },
            });
            store.dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
            history.push('/');
          } else {
            res = err.response;
          }
        });
      break;
    case 'postWithoutToken':
      await axios({
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'application/json',
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = err.response;
        });
      break;

    default:
      return true;
  }

  // var parsdata = await checkData(res);
  return res;
};

export const checkData = (data) => {
  return true;
};
