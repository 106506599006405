export const setHorizontalBanner = (value, total) => {
  return {
    type: 'SET_HORIZONTAL_BANNER',
    payload: { data: value, total },
  };
};

export const setPageBanner = (value, total) => {
  return {
    type: 'SET_PAGE_BANNER',
    payload: { data: value, total },
  };
};

export const setVerticalBlock = (value, total) => {
  return {
    type: 'SET_VERTICAL_BLOCK',
    payload: { data: value, total },
  };
};

export const setScrollerBanner = (value, total) => {
  return {
    type: 'SET_SCROLLER_BANNER',
    payload: { data: value, total },
  };
};

export const setHorizontalBlock = (value, total) => {
  return {
    type: 'SET_HORIZONTAL_BLOCK',
    payload: { data: value, total },
  };
};
