
  
const initState = {
    cart:{},
    PONumber:''
  };
  
  const CartReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_CART':
        return {
          ...state,
          cart: action.payload.data,
        };
      case 'RESET_CART_STATE':
        return {
          ...state,
          ...initState
        };
        case 'SET_PO_NUMBER':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default CartReducer;
  