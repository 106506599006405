export const setMyCatalogue = (
  value,
  page,
  limit,
  count,
  filter,
  sort,
  searchType,
  startPrice,
  endPrice
) => {
  return {
    type: "SET_MYCATALOGUE",
    payload: {
      data: value,
      page,
      limit,
      count,
      filter,
      sort,
      searchType,
      startPrice,
      endPrice,
    },
  };
};

export const setAllCatalogueProducts = (value) => {
  return {
    type: "SET_ALL_CATALOGUE_PRODUCTS",
    payload: { data: value },
  };
};
export const setTopProducts = (value) => {
  return {
    type: "SET_TOP_PRODUCTS",
    payload: { data: value },
  };
};
