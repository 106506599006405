import api from "../../../api/api";
export const setSearchMyCatalogue = (value) => {
    // console.log("Here", value);
    return {
      type: 'SET_SEARCH_MYCATALOGUE',
      payload: { data: value }
    };
  };

export const setSearchTypeMyCatalogue = (value) => {
  // console.log("Here", value);
  return {
    type: 'SET_SEARCHTYPE_MYCATALOGUE',
    payload: { data: value }
  };
};

export const setFilterMyCatalogue = (value) => {
  return {
    type: 'SET_FILTER_MYCATALOGUE',
    payload: { data: value }
  };
};

  export const setCategoryMyCatalogue = (value) => {
    // console.log("Here Category", value);
    return {
      type: 'SET_CATAGORY_MYCATALOGUE',
      payload: { data: value }
    };
  };

  export const setCartCount = (value) => {
    // console.log("Here cart", value);
    return {
      type: 'SET_CART_COUNT',
      payload: { data: value }
    };
  };

  export const setCategoryList = (value) => {
    // console.log("Here list", value);
    return {
      type: 'SET_CATEGORY_LIST',
      payload: { data: value }
    };
  };
  export const setFilterState = (value) => {
    return {
      type: 'SET_FILTER_STATE',
      payload: { data: value }
    };
  };